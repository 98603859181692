import React from "react"
import { TextField } from "app/core/components/TextField"
import { Form, FORM_ERROR } from "app/core/components/Form"

type CategoryFormProps = {
  isInline?: boolean
  initialValues: any
  onSubmit: React.FormEventHandler<HTMLFormElement>
}

const CategoryForm = ({ isInline = false, initialValues, onSubmit }: CategoryFormProps) => {
  return (
    <Form
      initialValues={initialValues}
      submitText="Create"
      onSubmit={async (values) => {
        try {
          return onSubmit(values)
        } catch (error) {
          return {
            [FORM_ERROR]:
              "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
          }
        }
      }}
      style={isInline ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}}
      isInline={isInline}
    >
      <TextField
        name="name"
        placeholder="e.g. Streaming services"
        isInline={isInline}
        outerCss={{ mb: 0 }}
      />
    </Form>
  )
}

export default CategoryForm
