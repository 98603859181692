import { useEffect, useState } from "react"
import Sheet from "react-modal-sheet"
import { useField, FieldArray } from "formik"
import { motion } from "framer-motion"
import { useQuery, useMutation } from "blitz"
import get from "lodash.get"
import { Badge, Box, Button, Container, Flex, Tag, Text, useColorModeValue } from "@chakra-ui/react"
import getCategories from "app/categories/queries/getCategories"
import getSubsCount from "app/subs/queries/getSubsCount"
import createCategory from "app/categories/mutations/createCategory"
import CategoryForm from "app/categories/components/CategoryForm"
import { AnimatedButton } from "app/core/components/AnimatedButton"
import { CheckIcon } from "@chakra-ui/icons"
import { usePrevious } from "app/core/hooks/usePrevious"

import { capitalizeFirstLetter } from "../../../utils/string"
import theme from "../../theme"
import deleteCategory from "../mutations/deleteCategory"

const SheetContainer = Sheet.Container as any
const SheetHeader = Sheet.Header as any
const SheetBackdrop = Sheet.Backdrop as any

const TinyCategoryForm = ({ onSubmit }) => {
  const [isCreating, setCreating] = useState<boolean>(false)

  return (
    <Flex sx={{ py: 3 }}>
      {isCreating ? (
        <CategoryForm
          initialValues={{}}
          onSubmit={async (values) => {
            await onSubmit(values)
            setCreating(false)
          }}
          isInline
        />
      ) : (
        <AnimatedButton
          sx={{ fontWeight: 600 }}
          onClick={() => {
            setCreating(true)
          }}
        >
          Create new category
        </AnimatedButton>
      )}
    </Flex>
  )
}

export const CategoriesPickerField = ({ name, label = "Category", placeholder, ...props }) => {
  const [{ categories }, { setQueryData }] = useQuery(getCategories, {})
  const [createCategoryMutation] = useMutation(createCategory)
  const [field] = useField(name)
  const [isOpen, setOpen] = useState<boolean>(false)

  const getCategoryLabel = (categoryId) => {
    return (categories || []).find((c) => c.id === categoryId)?.name
  }

  const bg = useColorModeValue("white", "gray.800")
  const textColor = useColorModeValue("blackAlpha.400", "whiteAlpha.400")
  const buttonBg = useColorModeValue("gray.200", "gray.600")
  const buttonHoverBg = useColorModeValue("gray.100", "gray.700")

  const renderCategories = () => {
    return (field.value || []).map((cId) => (
      <Tag size="sm" key={cId} variant="solid" colorScheme="blue" mr={1}>
        {getCategoryLabel(cId)}
      </Tag>
    ))
  }

  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <>
          {props.children && typeof props.children === "function" ? (
            props.children({
              categories: (field.value || []).map((cId) => categories.find((c) => c.id === cId)),
              setOpen,
            })
          ) : (
            <>
              {!!label && (
                <Text fontWeight={500} mb={2}>
                  {label}
                </Text>
              )}
              <Button
                type="button"
                onClick={() => setOpen(true)}
                sx={{
                  px: 4,
                  py: 3,
                  boxShadow: "none",
                  bg: buttonBg,
                  fontSize: 16,
                  fontWeight: 400,
                  fontFamily: "Rubik",
                  height: "40px",
                  justifyContent: "flex-start",
                  overflow: "auto",
                  ":hover": {
                    bg: buttonHoverBg,
                  },
                  ...props.sx,
                }}
              >
                <Text
                  sx={{
                    color: field.value ? "inherit" : textColor,
                  }}
                >
                  {!field.value || !field.value.length ? placeholder : renderCategories()}
                </Text>
              </Button>
            </>
          )}
          <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[450, 0]}>
            <SheetContainer style={{ backgroundColor: get(theme.colors, bg) }}>
              <SheetHeader />
              <Sheet.Content>
                <Container maxW="container.lg">
                  <Flex sx={{ flexDirection: "column", py: 6 }}>
                    {categories.map((category) => (
                      <motion.a
                        key={category.id}
                        whileHover={{ transition: { duration: 1 }, cursor: "pointer" }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                          ;(field.value || []).includes(category.id)
                            ? remove(field.value.indexOf(category.id))
                            : push(category.id)
                        }}
                      >
                        <Flex
                          key={category.id}
                          sx={{
                            alignItems: "center",
                            py: 3,
                            transition: "all .2s ease-in-out",
                            ":hover": {
                              cursor: "pointer",
                              transform: "scale(0.98)",
                              opacity: 0.75,
                            },
                          }}
                        >
                          {(field.value || []).includes(category.id) && (
                            <CheckIcon mr={2} w={4} h={4} color="blue.500" />
                          )}
                          <Text size="6" sx={{ fontWeight: 450 }}>
                            {capitalizeFirstLetter(category.name)}
                          </Text>
                        </Flex>
                      </motion.a>
                    ))}
                    <TinyCategoryForm
                      onSubmit={async (values) => {
                        try {
                          const category = await createCategoryMutation({ data: values })
                          setQueryData({ categories: [...categories, category] })
                          push(category?.id)
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                  </Flex>
                </Container>
              </Sheet.Content>
            </SheetContainer>

            <SheetBackdrop onTap={() => setOpen(false)} />
          </Sheet>
          <style jsx global>
            {`
              html,
              body {
                overflow: ${isOpen ? "hidden" : "initial"};
              }
              .react-modal-sheet-content {
                touch-action: initial !important;
              }
            `}
          </style>
        </>
      )}
    </FieldArray>
  )
}

type CategoriesPickerProps = {
  label?: string
  children: ({ values: any }) => React.ReactNode
  readOnly?: boolean
  onChange?: (values: any) => void
}

const CategoryRow = ({ id, name, onSelect, onDelete, isSelected }) => {
  const [deleteCategoryMutation, { isLoading: isDeleting }] = useMutation(deleteCategory)
  const [{ count }] = useQuery(getSubsCount, {
    where: {
      categories: {
        some: {
          id: id === "all" ? 0 : id,
        },
      },
    },
  })

  return (
    <motion.a
      whileHover={{ transition: { duration: 1 }, cursor: "pointer" }}
      whileTap={{ scale: 0.95 }}
      onClick={(e) => {
        e.preventDefault()
        onSelect()
      }}
    >
      <Flex
        key={id}
        sx={{
          justifyContent: "space-between",
          py: 3,
          transition: "all .2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            transform: "scale(0.98)",
            opacity: 0.75,
          },
        }}
      >
        <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
          {isSelected && <CheckIcon mr={2} w={4} h={4} color="blue.500" />}
          <Text size="6" pr="2" sx={{ fontWeight: 600 }}>
            {capitalizeFirstLetter(name)}
          </Text>
          {id !== "all" && <Badge>{count}</Badge>}
        </Flex>
        {id !== "all" && count === 0 && (
          <Button
            size="xs"
            colorScheme="red"
            isLoading={isDeleting}
            onClick={async (e) => {
              e.stopPropagation()
              await deleteCategoryMutation({ where: { id } })
              onDelete(id)
            }}
          >
            Delete
          </Button>
        )}
      </Flex>
    </motion.a>
  )
}

const CategoriesPicker: React.FC<CategoriesPickerProps> = ({
  label = "Choose a category",
  children,
  readOnly = false,
  onChange,
  ...props
}) => {
  const [{ categories }, { setQueryData }] = useQuery(getCategories, {})
  const [createCategoryMutation] = useMutation(createCategory)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [values, setValues] = useState<any[]>([])
  const wasOpen = usePrevious(isOpen)

  const getCategory = (category) => {
    return categories.find((c) => c.id === category)
  }

  useEffect(() => {
    if (onChange) {
      onChange(values)
    }
  }, [values, wasOpen, isOpen])

  const bg = useColorModeValue("white", "gray.800")

  return (
    <>
      <Text
        as="a"
        sx={{ alignSelf: "stretch", overflow: "hidden", ":hover": { cursor: "pointer" } }}
        mr={6}
        onClick={(e) => {
          e.preventDefault()
          if (!readOnly) {
            setOpen(true)
          }
        }}
      >
        {children({ values: values.map(getCategory) })}
      </Text>
      <Sheet
        isOpen={isOpen}
        onClose={() => {
          if (!readOnly) {
            setOpen(false)
          }
        }}
        snapPoints={[400, 0]}
      >
        <SheetContainer style={{ backgroundColor: get(theme.colors, bg) }}>
          <SheetHeader />
          <Sheet.Content>
            <Container maxW="container.lg">
              <Flex sx={{ flexDirection: "column", py: 6 }}>
                {[{ id: "all", name: "All Subscriptions" }, ...categories].map((category) => (
                  <Box key={category.id}>
                    <CategoryRow
                      {...category}
                      onSelect={() => {
                        if (values.includes(category.id)) {
                          setValues(values.filter((v) => v !== category.id))
                        } else {
                          setValues(category.id === "all" ? [] : [...values, category.id])
                        }
                        if (!readOnly && category.id === "all") {
                          setOpen(false)
                        }
                      }}
                      onDelete={(id) => {
                        setQueryData({ categories: categories.filter((c) => c.id !== id) })
                      }}
                      isSelected={
                        (values || []).includes(category.id) ||
                        (category.id === "all" && !values?.length)
                      }
                    />
                  </Box>
                ))}
                <TinyCategoryForm
                  onSubmit={async (values) => {
                    try {
                      const category = await createCategoryMutation({ data: values })
                      setQueryData({ categories: [...categories, category] })
                    } catch (error) {
                      console.error(error)
                      // alert("Error creating category " + JSON.stringify(error, null, 2))
                    }
                  }}
                />
              </Flex>
            </Container>
          </Sheet.Content>
        </SheetContainer>

        <SheetBackdrop
          onTap={() => {
            if (!readOnly) {
              setOpen(false)
            }
          }}
        />
      </Sheet>
      <style jsx global>
        {`
          html,
          body {
            overflow: ${isOpen ? "hidden" : "initial"};
          }
          .react-modal-sheet-content {
            touch-action: initial !important;
          }
        `}
      </style>
    </>
  )
}

export default CategoriesPicker
